import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";

const useStyles = makeStyles({
  searchTextBox: {
    "& .MuiInputBase-root": {
      height: "2.4rem",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    display: "flex",
    flex: 1,
    border: "none",
    backgroundColor: "#E5F1FD",
    borderRadius: "2rem",
    paddingLeft: "1rem",
  },
  noBorder: {
    "&:before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
  },
});

const SearchField = ({
  searchText,
  handleSearchText,
  placeholder = "Search",
  handleClearClick,
}) => {
  const classes = useStyles();
  return (
    <TextField
      value={searchText}
      onChange={handleSearchText}
      fullWidth
      placeholder={placeholder}
      className={classes.searchTextBox}
      InputProps={{
        classes: {
          underline: classes.noBorder,
        },
        startAdornment: <SearchIcon className="primary__icon mr-10" />,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton className="pd-5 mr-10" onClick={handleClearClick}>
              <CloseIcon className="grey__icon icons_small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default memo(SearchField);
